import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useBaseUtils from "~/functions/useBaseUtils";
import useWindow from "~/functions/useWindow";
export default {
  __name: 'CiqTranscriptTable',
  props: {
    dense: {
      type: Boolean,
      "default": false
    },
    loading: {
      type: Boolean,
      "default": false
    },
    inititemsperpage: {
      type: Number,
      "default": 20
    },
    transcripts: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      "default": function _default() {
        return [];
      } // TODO: make a reasonable default value
    },
    footer: {
      type: Object,
      "default": function _default() {
        return {
          itemsPerPageOptions: [10, 20, 30, 40, -1]
        };
      }
    },
    watchlist: {
      type: Boolean,
      "default": false
    },
    cidMap: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      userEpochToShortDate = _useDateTimeFormatter.userEpochToShortDate;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useWindow = useWindow(props, "CiqTranscriptTable"),
      tableHeight = _useWindow.tableHeight;
    var formatDate = function formatDate(dateStr) {
      var dateObj = new Date(dateStr);
      return userEpochToShortDate.value.format(dateObj);
    };
    var transColumns = computed(function () {
      return props.columns.map(function (c) {
        return _objectSpread(_objectSpread({}, c), {}, {
          text: I18nFn(c.text)
        });
      });
    });
    var ticker = computed(function () {
      return $store.state.ciq.ticker;
    });
    return {
      __sfc: true,
      props: props,
      userEpochToShortDate: userEpochToShortDate,
      refCode: refCode,
      I18nFn: I18nFn,
      $store: $store,
      tableHeight: tableHeight,
      formatDate: formatDate,
      transColumns: transColumns,
      ticker: ticker
    };
  }
};